/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */
import React from "react"
import { Helmet } from "react-helmet"
import { graphql, useStaticQuery } from "gatsby"
import { string } from "prop-types"

const Seo = ({ title, author, description, image, keywords, lang }) => {
  const { site } = useStaticQuery(
    graphql`
      query default {
        site {
          siteMetadata {
            title
            author
            description
            image
          }
        }
      }
    `
  )
  const siteDescription = description || site.siteMetadata.description
  const siteTitle = title || site.siteMetadata.title
  const siteAuthor = author || site.siteMetadata.author
  const siteImage = image || site.siteMetadata.image
  const siteKeywords = keywords || ["Vow Green Metals"]

  return (
    <Helmet
      htmlAttributes={{ lang }}
      title={title}
      meta={[
        {
          name: "description",
          content: siteDescription,
        },
        {
          property: `og:title`,
          content: siteTitle,
        },
        {
          property: `og:description`,
          content: siteDescription,
        },
        {
          property: `og:image`,
          content: siteImage,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: siteAuthor,
        },
        {
          name: `twitter:title`,
          content: siteTitle,
        },
        {
          name: `twitter:description`,
          content: siteDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
      ].concat({
        name: `keywords`,
        content: siteKeywords.join(","),
      })}
    />
  )
}

Seo.propTypes = {
  title: string.isRequired,
  author: string,
  description: string,
  image: string,
  lang: string,
  // keywords: arrayOf(string).isRequired,
}
Seo.defaultProps = {
  author: "",
  description: "",
  image: "",
  lang: "eng",
}

export default Seo
